import { Component } from 'react';

import { GLOBAL } from 'saddlebag-browser';

import ScanForApp from '../../ScanForApp';
import AppStoreWidget from '../AppStoreWidget';
import MainText from '../MainText';
import PanelContainer from '../PanelContainer';
import ScreenshotsWidget from '../ScreenshotsWidget';

import type {
  DesktopUrl,
  MobileLandingImages,
} from 'common-types/types/MobileLanding';
import type { StringTranslations } from 'common-types/types/components';

import STYLES from './TopPanel.module.scss';

type TopPanelProps = {
  description: string;
  images: MobileLandingImages;
  scanForAppProps: {
    strings: StringTranslations;
    [key: string]: any;
  };
  title: string;
  desktopUrl: DesktopUrl;
};

type TopPanelState = {
  isClientRender?: boolean;
};

class TopPanel extends Component<TopPanelProps, TopPanelState> {
  constructor(props: TopPanelProps) {
    super(props);
    this.state = { isClientRender: false };
  }

  componentDidMount() {
    this.setState({ isClientRender: true });
  }

  render() {
    const { description, desktopUrl, images, scanForAppProps, title } =
      this.props;
    const $window = GLOBAL.getWindow();
    const search = $window.location ? $window.location.search : '';

    const params = Object.fromEntries(new URLSearchParams(search));
    const urlParameters = Object.fromEntries(
      Object.entries(params).map(([k, v]) => [k.toLowerCase(), v]),
    );

    // Intentionally skip the deeplink ID on server-side and initial hydration renders,
    // so that when the second render happens on the client side, it will change the
    // attribute and correctly cause the HTML to be updated.
    let linkPathAndParams = '';
    if (this.state.isClientRender) {
      linkPathAndParams = String(urlParameters.deeplink || '');
      const bookingType = urlParameters.bookingtype || null;
      const bookingId = urlParameters.skyscannerbookingid || null;
      if (bookingType && bookingId) {
        linkPathAndParams = `${linkPathAndParams}?skyscannerbookingid=${bookingId}&bookingtype=${bookingType}&source=Internal_referral`;
      }
    }
    const deeplinkUrl = `https://skyscanner.app.link/${linkPathAndParams}`;
    const scanForAppLink = 'https://skyscanner.app.link/Tlk3AAMLGNb';

    return (
      <PanelContainer
        isPrimary
        imageSrc="https://content.skyscnr.com/m/275e105142ee00c9/original/Mobile-landing-hero.jpg?resize=9999px:9999px&quality=70"
      >
        <div className={STYLES.TwoColumnPanel__column}>
          <div className={STYLES.PrimaryContentContainer}>
            <MainText titleString={title} subtitleString={description} />
            <div className={STYLES.ScanForApp}>
              <ScanForApp {...scanForAppProps} deeplinkUrl={scanForAppLink} />
            </div>
            <AppStoreWidget
              imageSrc={{
                appStore: images.oneBigAdventure_appstore,
                playStore: images.oneBigAdventure_playstore,
              }}
              deeplinkUrl={deeplinkUrl}
              desktopUrl={desktopUrl}
            />
          </div>
        </div>
        <div className={STYLES.TwoColumnPanel__column}>
          <ScreenshotsWidget
            screenshotsSrc={{
              ios: images.oneBigAdventure_ios,
              android: images.oneBigAdventure_android,
            }}
          />
        </div>
      </PanelContainer>
    );
  }
}

export default TopPanel;
